import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, useTheme, SxProps, useMediaQuery, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles/text-animation.css';
import { AndroidBetaTest } from './AndrodBetaTest';
import PromoVideo from './PromoVideo';
import AppleSidebar from './AppleSidebar';
import ScreenshotSlider from './ScreenshotSlider';
import AnimatedTextSection from './AnimatedText';
import { Section, sections } from './Section';

export const qrCodeUrl = '/images/qr-code.jpg';

const AboutPage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLarge = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const darkMode = theme.palette.mode === 'dark';

  const carouselSettings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: isMobile ? 1 : 2,
    centerMode: true,
    centerPadding: '0',
    className: 'center',
    adaptiveHeight: true,
    fade: false,
    rows: 1,
    autoplay: true,
    swipeToSlide: true,
  };

  const carouselWidth = isMobile ? '300px' : '650px';
  const screenshotWidth = '300px';
  const screenshotHeight = '650px';
  const screenshotStyle = { width: screenshotWidth, height: screenshotHeight, borderRadius: '10px' };

  const gradient = darkMode
    ? 'linear-gradient(to bottom, #424242, black)'
    : 'linear-gradient(to bottom, lightblue, #e0e0e0)';

  const sliderContainerStyle: SxProps = { padding: '0px' };



  // State for parallax effect
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Box
  sx={{
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    position: 'relative',
    gap: { xs: 0, md: 4 },
    backgroundImage: darkMode ? 'url(/images/about_dark.webp)' : 'url(/images/about_light.webp)',
    backgroundAttachment: 'fixed', // Makes the background fixed for parallax effect
    backgroundSize: 'cover', // Ensures the background covers the entire area
    // backgroundRepeat: 'no-repeat', // Prevents the image from repeating
    backgroundPosition: `center ${offsetY * 0.05}px`, // Adjusts position based on scroll
  }}
>
  {/* Left Sticky Sidebar */}
  <AppleSidebar qrCodeUrl={qrCodeUrl} />
  {/* Main Content */}
  <Container
    sx={{
      pb: 10,
    }}
  >
    {/* Add padding to the bottom to prevent overlap with footer */}
    <Box
      sx={{
        mt: 4,
        textAlign: 'center',
      }}
    >
      {!isMobile && <AnimatedTextSection />}
      {isMobile && (
        <Box sx={{ fontSize: '3rem', mt: -2, mb: 10 }}>
          {t('createAndShareLoveCoupons')}
        </Box>
      )}
      {/* Full-Width Promo Image */}
      <Box
        sx={{
          width: '100%',
          mt: 4,
        }}
      >
        <img
          src="/images/screenshots_mac.webp"
          alt="Promo of Mac, iPad, and iPhone with app screenshots"
          style={{
            width: isMobile
              ? '350px'
              : isMedium
              ? '500px'
              : isLarge
              ? '700px'
              : isExtraLarge
              ? '1000px'
              : '100%',
            borderRadius: '10px',
          }}
        />
      </Box>

      <Box
        sx={{
          width: carouselWidth,
          height: screenshotHeight,
          margin: 'auto',
          mt: { md: 10 },
        }}
      >
        <ScreenshotSlider
          carouselSettings={carouselSettings}
          sliderContainerStyle={sliderContainerStyle}
          screenshotStyle={screenshotStyle}
          images={[
            { src: '/images/screenshot1.webp', altKey: 'screenshot1Alt' },
            { src: '/images/screenshot2.webp', altKey: 'screenshot2Alt' },
            { src: '/images/screenshot3.webp', altKey: 'screenshot3Alt' },
            { src: '/images/screenshot4.webp', altKey: 'screenshot4Alt' },
            { src: '/images/screenshot5.webp', altKey: 'screenshot5Alt' },
          ]}
        />
      </Box>
      <Typography
        variant="h4"
        component="h2"
        sx={{
          margin: { md: 12 },
          marginTop: { xs: 8 },
          marginBottom: { xs: 8 },
          p: 2,
          fontSize: { xs: '1.5rem' },
        }}
      >
        {t('welcomeToCoupons')}
      </Typography>

      <PromoVideo />
      <Box sx={{ mt: 4 }}></Box>

      <Typography variant="h2" component="h2" gutterBottom>
        {t('keyFeatures')}
      </Typography>

      {sections.map((section, index) => (
        <Section key={section.title} section={section} index={index} />
      ))}
    </Box>
  </Container>

  {/* Right Sticky Sidebar */}
  <AndroidBetaTest />
</Box>
  );
};

export default AboutPage;