import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AnimatedTextSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        mt: { xs: -20, md: 10 },
        mb: { md: 20, xs: 10 },
        position: 'relative',
        height: '200px',
      }}
    >
      <div className="sp-container">
        <div className="sp-content">
          <h2 className="frame-1">{t('createAndShareLoveCoupons')}</h2>
          <h2 className="frame-2">{t('surpriseYourLovedOnes')}</h2>
          <h2 className="frame-3">{t('personalizedAndUnique')}</h2>
          <h2 className="frame-4">{t('easyToUse')}</h2>
          <h2 className="frame-5">
            <span>{t('create')}</span>
            <span>{t('share')}</span>
            <span>{t('enjoy')}</span>
          </h2>
        </div>
      </div>
    </Box>
  );
};

export default AnimatedTextSection;