import React from 'react';
import { Box } from '@mui/material';
import Slider, { Settings } from 'react-slick';
import { useTranslation } from 'react-i18next';

interface ScreenshotSliderProps {
  carouselSettings: Settings;
  sliderContainerStyle: object;
  screenshotStyle: React.CSSProperties;
  images: { src: string; altKey: string }[];
}

const ScreenshotSlider: React.FC<ScreenshotSliderProps> = ({
  carouselSettings,
  sliderContainerStyle,
  screenshotStyle,
  images,
}) => {
  const { t } = useTranslation();

  return (
    <Slider {...carouselSettings}>
      {images.map((image, index) => (
        <Box key={index} sx={sliderContainerStyle}>
          <img
            src={image.src}
            alt={t(image.altKey)}
            style={screenshotStyle}
          />
        </Box>
      ))}
    </Slider>
  );
};

export default ScreenshotSlider;