import React, { useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles/text-animation.css';

export const sections = [
	{ title: 'whyChooseCoupons', text: 'whyChooseCouponsText', bgColor: 'rgba(221, 160, 221, 0.5)', image: '/images/intro_coupon.webp', width: 200, height: 200 }, // Light Purple
	{ title: 'createCustomCoupons', text: 'createCustomCouponsText', bgColor: 'rgba(93, 116, 130, 0.5)', image: '/images/create_coupon.webp', width: 200, height: 200 }, // Light Blue
	{ title: 'shareWithLovedOnes', text: 'shareWithLovedOnesText', bgColor: 'rgba(144, 238, 144, 0.5)', image: '/images/share_coupon.webp', width: 200, height: 200 }, // Light Green
	{ title: 'redeemAndTrack', text: 'redeemAndTrackText', bgColor: 'rgba(221, 160, 221, 0.5)', image: '/images/redeem_coupon.webp', width: 200, height: 200 }, // Light Purple
	{ title: 'notificationAlerts', text: 'notificationAlertsText', bgColor: 'rgba(93, 116, 130, 0.5)', image: '/images/bell.webp', width: 200, height: 200 }, // Light Blue
	{ title: 'secureAndPrivate', text: 'secureAndPrivateText', bgColor: 'rgba(144, 238, 144, 0.5)', image: '/images/lock.webp', width: 200, height: 200 }, // Light Green
  ];
  
  

export const Section: React.FC<{ section: typeof sections[0]; index: number }> = ({ section, index }) => {
	const { t } = useTranslation();
	const { ref, inView } = useInView({
	  triggerOnce: true,
	  threshold: 0.2,
	});
  
	useEffect(() => {
	  const timeoutId = setTimeout(() => {
		document.querySelectorAll('.sp-container h2').forEach((el) => {
		  el.classList.add('animate-h2');
		});
  
		document.querySelector('.sp-container h2.frame-1')?.classList.add('animate-frame-1');
		document.querySelector('.sp-container h2.frame-2')?.classList.add('animate-frame-2');
		document.querySelector('.sp-container h2.frame-3')?.classList.add('animate-frame-3');
		document.querySelector('.sp-container h2.frame-4')?.classList.add('animate-frame-4');
		document.querySelector('.sp-container h2.frame-5')?.classList.add('animate-frame-5');
  
		document.querySelector('.sp-globe')?.classList.add('animate-globe');
	  }, 300);
  
	  return () => clearTimeout(timeoutId);
	}, []);
  
	return (
	  <motion.div
		ref={ref}
		initial={{ opacity: 0, x: index % 2 === 0 ? -100 : 100 }}
		animate={inView ? { opacity: 1, x: 0 } : {}}
		transition={{ duration: 0.5 }}
	  >
		<Box
		  sx={{
			mt: 2,
			p: 2,
			borderRadius: 2,
			backgroundColor: section.bgColor,
			display: 'flex',
			flexDirection: { xs: 'column', md: index % 2 === 0 ? 'row' : 'row-reverse' },
			alignItems: 'center',
			gap: 2,
		  }}
		>
		  <Box sx={{ flex: 1 }}>
			<Typography variant="h3" component="h3" gutterBottom sx={{ fontFamily: '"Playwrite GB J", cursive' }}>
			  {t(section.title)}
			</Typography>
			<Typography variant="body1" paragraph>
			  {t(section.text)}
			</Typography>
		  </Box>
		  <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Box
			  component="img"
			  src={section.image}
			  alt={t(section.title)}
			  sx={{ borderRadius: '50%', backgroundColor: 'white', padding: '10px' }}
			  width={section.width}
			  height={section.height}
			/>
		  </Box>
		</Box>
	  </motion.div>
	);
  };