import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface AppleSidebarProps {
  qrCodeUrl: string;
}

const AppleSidebar: React.FC<AppleSidebarProps> = ({ qrCodeUrl }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        position: { md: 'sticky' },
        top: { md: 310 },
        alignSelf: 'flex-start',
        width: { xs: '100%', md: '200px' },
        mr: { md: 4 },
        mb: { xs: 4, md: 0 },
        mt: { md: 2 },
        ml: { md: 4 },
        zIndex: 10000,
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          my: 2,
          p: 2,
          borderRadius: 2,
          backgroundColor: 'rgba(221, 160, 221, 0.5)',
        }}
      >
        <Typography
          gutterBottom
          sx={{
            mt: 2,
            textAlign: 'center',
            fontSize: '1.2rem',
          }}
        >
          {t('availableForIOSAndMacOS')}
        </Typography>
        <img
          src={qrCodeUrl}
          alt={t('qrCodeAlt')}
          style={{
            width: '150px',
            height: '150px',
            marginBottom: '20px',
            borderRadius: '5px',
          }}
        />
        <a
          href="https://apps.apple.com/us/app/coupons-by-lokidev/id6553964089?itsct=apps_box_badge&amp;itscg=30200"
          style={{
            display: 'inline-block',
            overflow: 'hidden',
            borderRadius: '5px',
            width: '100%',
            height: 'auto',
          }}
        >
          <img
            loading="lazy"
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1722643200"
            alt="Download on the App Store"
            style={{
              borderRadius: '5px',
              width: isMobile ? '270px' : '120px',
              height: isMobile ? '90px' : '40px',
            }}
          />
        </a>
      </Box>
    </Box>
  );
};

export default AppleSidebar;