import React from 'react';

const PromoVideo = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <video
        controls
        style={{ maxWidth: '886px', height: 'auto', width: '375px' }}
      >
        <source
          src="https://firebasestorage.googleapis.com/v0/b/coupons-68edf.appspot.com/o/promo_mp4.mp4?alt=media&token=9005741f-c418-412a-b4f0-8b3017ed988d"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default PromoVideo;